import React from "react";

import { useTranslation } from "react-i18next";

import { Text } from "src/components/shared";

import "./regulations.scss";

import Competition from "src/assets/images/competition.png";
import CompetitionEn from "src/assets/documents/competition_en.pdf";
import CompetitionHy from "src/assets/documents/competition_hy.pdf";
import CompetitionRu from "src/assets/documents/competition_ru.pdf";
import Plan from "src/assets/images/plan.png";
import PlanEn from "src/assets/documents/plan_en.pdf";
import PlanHy from "src/assets/documents/plan_hy.pdf";
import PlanRu from "src/assets/documents/plan_ru.pdf";
import Statute from "src/assets/images/statute-ru.png";
import StatuteHy from "src/assets/documents/statute_hy.pdf";
import StatuteEn from "src/assets/documents/statute_en.pdf";
import StatuteRu from "src/assets/documents/statute_ru.pdf";

const DOCUMENTS: Record<string, Record<string, string>> = {
  AM: {
    competition: CompetitionHy,
    plan: PlanHy,
    statute: StatuteHy,
  },
  GB: {
    competition: CompetitionEn,
    plan: PlanEn,
    statute: StatuteEn,
  },
  RU: {
    competition: CompetitionRu,
    plan: PlanRu,
    statute: StatuteRu,
  },
};

export const RegulationsComponent = React.memo(() => {
  const { i18n } = useTranslation();

  return (
    <div className="regulations">
      <div className="regulations__regulation">
        <img className="regulations__img" src={Plan} alt="" />
        <div className="regulations__label">
          <a href={DOCUMENTS[i18n.language]["plan"]}>
            <Text className="regulations__link" path="regulations.plan" />
          </a>
        </div>
      </div>
      <div className="regulations__regulation">
        <img className="regulations__img" src={Competition} alt="" />
        <div className="regulations__label">
          <a href={DOCUMENTS[i18n.language]["competition"]}>
            <Text className="regulations__link" path="regulations.competition" />
          </a>
        </div>
      </div>
      <div className="regulations__regulation">
        <img className="regulations__img" src={Statute} alt="" />
        <div className="regulations__label">
          <a href={DOCUMENTS[i18n.language]["statute"]}>
            <Text className="regulations__link" path="regulations.statuteRU" />
          </a>
        </div>
      </div>
    </div>
  );
});

RegulationsComponent.displayName = "RegulationsComponent";
